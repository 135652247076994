import { format, parse } from 'date-fns'
import {
  addDoc,
  collection,
  collectionGroup, deleteDoc, doc,
  getDoc,
  getDocs, increment, query, serverTimestamp, setDoc, updateDoc, where
} from 'firebase/firestore'
import { times } from '../constants/adminCalendar'
import { concurrentPatients } from '../constants/config'
import * as STATUS from '../constants/status'
import { appointment, appointmentConfig, employee, openinghours, user } from '../typings'
import { firestore } from './firebaseConfig'

export const doesEmailExist = async (email: string) => {
  const q = query(collection(firestore, 'users'), where('email', '==', email))
  const queryResult = await getDocs(q)
  if (queryResult.empty) return false
  return true
}

export const getUserIdByEmail = async (email: string) => {
  const q = query(collection(firestore, 'users'), where('email', '==', email))
  const result = await getDocs(q)

  const [user]: user[] = result.docs.map((document) => document.data() as user)

  return user
}

export const getUserByUserId = async (uid: string) => {
  const profileRef = doc(firestore, 'users', uid)
  const result = await getDoc(profileRef)

  const user: user = result.data() as user

  return user
}

export const getAppointmentsByUserId = async (uid: string) => {
  const q = query(collection(firestore, 'users', uid, 'appointments'))
  const result = await getDocs(q)

  const appointments: appointment[] = result.docs.map(
    (document) =>
      ({
        ...document.data(),
        createdAt: document.data().createdAt.toDate(),
        date: document.data().date.toDate(),
      } as appointment)
  )

  return appointments
}

/**
 *
 * @param uid uid of person appointment is made for
 * @param date date including time of appointment
 * @param email email of person appointment is made for
 * @param maxAppointments max appointments possible for date
 * @param name name of user appointment is made for
 * @param action (optional) delay
 * @returns possible error
 */

export const makeAppointment = async (
  uid: string,
  date: Date,
  email: string,
  maxAppointments: number,
  name: string,
  action?: string
) => {
  const q = query(
    collectionGroup(firestore, 'appointments'),
    where('date', '==', format(date, 'dd.MM.yyyy')),
    where('time', '==', format(date, 'kk:mm')),
    where('status', '==', STATUS.ACTIVE)
  )

  const res = await getDocs(q)

  // Only allow making an appointment if it hasn't been reserved in the meantime
  if (res.empty || res.docs.length < concurrentPatients) {
    const userRef = doc(firestore, 'users', uid)
    const appointmentsRef = collection(firestore, 'users', uid, 'appointments')

    // Do not increment on delay, one appointment gets cancelled and one more created
    // so it evens out
    if (action !== 'delay')
      await updateDoc(userRef, {
        amountActiveAppointments: increment(1),
      })

    await addDoc(appointmentsRef, {
      createdAt: serverTimestamp(),
      dateAsObject: date,
      date: format(date, 'dd.MM.yyyy'), // for filtering purposes
      time: format(date, 'kk:mm'), // for filtering purposes
      name,
      email,
      status: STATUS.ACTIVE,
    })

    await incrementAppointmentsOnDate(date, maxAppointments)
  } else return { error: true }
}

const incrementAppointmentsOnDate = async (date: Date, maxAppointments: number) => {
  const calendarRef = doc(firestore, 'calendar', format(date, 'dd.MM.yyyy'))

  const result = await getDoc(calendarRef)
  const currentValue = result.data()?.appointments

  await setDoc(
    calendarRef,
    {
      appointments: increment(1),
      maxAppointments,
      bookedOut: currentValue + 1 === maxAppointments,
    },
    { merge: true }
  )
}

export const getBookedOutDays = async () => {
  const q = query(collection(firestore, 'calendar'), where('bookedOut', '==', true))

  const result = await getDocs(q)
  const bookedOutDays = result.docs.map((doc) => parse(doc.id, 'dd.MM.yyyy', new Date()))

  return bookedOutDays
}

const decrementAppointmentsOnDate = async (date: string) => {
  const calendarRef = doc(firestore, 'calendar', date)

  await updateDoc(calendarRef, {
    appointments: increment(-1),
    bookedOut: false,
  })
}

export const delayAppointment = async (
  uid: string,
  docId: string,
  newDate: Date,
  maxAppointments: number,
  email: string,
  oldDate: string,
  name: string
) => {
  try {
    await makeAppointment(uid, newDate, email, maxAppointments, name, 'delay')

    await updateDoc(doc(firestore, 'users', uid, 'appointments', docId), {
      status: STATUS.DELAYED,
    })

    await decrementAppointmentsOnDate(oldDate)
  } catch (error) {
    console.log(error)
  }
}

export const getAllAppointmentTimesAtDate = async (date: Date) => {
  const formattedDate = format(date, 'dd.MM.yyyy')
  const q = query(
    collectionGroup(firestore, 'appointments'),
    where('date', '==', formattedDate),
    where('status', '==', STATUS.ACTIVE)
  )
  const result = await getDocs(q)

  const appointments: Date[] = result.docs.map(
    (appointment) => appointment.data().dateAsObject.toDate() as Date
  )

  return appointments
}

export const cancelAppointment = async (uid: string, docId: string, date: string) => {
  const userRef = doc(firestore, 'users', uid)
  await updateDoc(userRef, {
    amountActiveAppointments: increment(-1),
    amountInactiveAppointments: increment(1),
  })

  await updateDoc(doc(firestore, 'users', uid, 'appointments', docId), {
    status: STATUS.CANCELLED,
  })

  await decrementAppointmentsOnDate(date)
}

export const addAppointmentReason = async (reason: string, uid: string, docId: string) => {
  await updateDoc(doc(firestore, 'users', uid, 'appointments', docId), {
    reason,
  })
}

export const getAmountOfAppointments = async (uid: string) => {
  const userRef = doc(firestore, 'users', uid)
  const result = await getDoc(userRef)

  const amountActiveAppointments: number = result.data()!.amountActiveAppointments
  const amountInactiveAppointments: number = result.data()!.amountInactiveAppointments
  return { amountActiveAppointments, amountInactiveAppointments }
}

// export const seedWeekdays = async () => {
//   const docRef = doc(firestore, 'config', 'openinghours')

//   await setDoc(docRef, openingHours)
// }

export const seedTimes = async () => {
  const docRef = doc(firestore, 'config', 'times')

  await setDoc(docRef, { times: times })
}

export const getOpeningHours = async () => {
  const docRef = doc(firestore, 'config', 'openinghours')

  const result = await getDoc(docRef)
  const openingHours = result.data()
  return openingHours as openinghours
}

export const updateOpeningHours = async (openingHours: openinghours) => {
  const docRef = doc(firestore, 'config', 'openinghours')

  await setDoc(docRef, openingHours)
}

export const getTimes = async () => {
  const docRef = doc(firestore, 'config', 'times')

  const result = await getDoc(docRef)
  const { times } = result.data() as { times: string[] }
  return times
}

export const updateTimes = async (timesArr: string[]) => {
  const docRef = doc(firestore, 'config', 'times')

  await setDoc(docRef, { times: timesArr })
}

export const getAppointmentConfig = async () => {
  const docRef = doc(firestore, 'config', 'appointments')

  const result = await getDoc(docRef)
  const appointmentConfig = result.data() as {
    amountNextAppointments: number
    appointmentDuration: number
    concurrentPatients: number
  }
  const { amountNextAppointments, appointmentDuration, concurrentPatients } = appointmentConfig
  return { amountNextAppointments, appointmentDuration, concurrentPatients }
}

export const updateAppointmentConfig = async (appointmentConfig: appointmentConfig) => {
  const docRef = doc(firestore, 'config', 'appointments')

  await setDoc(docRef, appointmentConfig)
}

export const getManualCalendarTimes = async () => {
  const docRef = doc(firestore, 'config', 'calendar')

  const result = await getDoc(docRef)
  const manualCalendarTimes = result.data() as { from: number | null; to: number | null }
  return manualCalendarTimes
}

export const updateManualCalendarTimes = async (newTimes: { from: number | null; to: number | null }) => {
  const { from, to } = newTimes
  const docRef = doc(firestore, 'config', 'calendar')

  await setDoc(docRef, { from, to })
}

export const getEmployeeUid = async (email: string) => {
  const q = query(collectionGroup(firestore, 'users'), where('email', '==', email))

  const result = await getDocs(q)
  const [user]: user[] = result.docs.map((document) => document.data() as user)

  return user.uid
}

export const addEmployee = async (uid: string, email: string) => {
  const employeeRef = doc(firestore, 'employees', uid)

  await setDoc(employeeRef, {
    uid,
    email,
  })
}

export const getAllEmployees = async () => {
  const employeesRef = collection(firestore, 'employees')

  const result = await getDocs(employeesRef)
  const employees: employee[] = result.docs.map((document) => document.data() as employee)

  return employees
}

export const removeEmployee = async (uid: string) => {
  await deleteDoc(doc(firestore, 'employees', uid))
}
